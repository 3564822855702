import { call, put, takeLatest } from "redux-saga/effects";

import { getRequestPath } from "src/utils/fetch";
import { getGlobalThis } from "src/utils/globals/document";
import { SliceActionType } from "../types";
import {
  FetchProjectFailedResponse,
  FetchProjectsAction,
  FetchProjectSuccessResponse,
  ReceiveProjectsAction,
} from "./types";

const createFetchProjects: (
  signal?: AbortController["signal"],
) => () => Promise<FetchProjectSuccessResponse | FetchProjectFailedResponse> = (
  signal,
) => {
  return async () => {
    try {
      const response = await fetch(getRequestPath("projects"), {
        credentials: "include",
        method: "GET",
        redirect: "follow",
        headers: new Headers({
          Referer: getGlobalThis().location.host,
        }),
        signal,
      });
      return response.json();
    } catch (error) {
      throw new Error(String(error));
    }
  };
};

function* fetchProjectsSaga(action: FetchProjectsAction) {
  try {
    const response: FetchProjectSuccessResponse | FetchProjectFailedResponse =
      yield call(createFetchProjects(action.payload.signal));
    if (response.errors) {
      yield put<ReceiveProjectsAction>({
        type: SliceActionType.ReceiveProjects,
        payload: {
          errors: response.errors,
        },
        meta: {
          title: "Errors received when attempting to fetch projects",
        },
      });
      return;
    }
    const { data, meta } = response;
    yield put<ReceiveProjectsAction>({
      type: SliceActionType.ReceiveProjects,
      payload: { data },
      meta,
    });
  } catch (error) {
    yield put<ReceiveProjectsAction>({
      type: SliceActionType.ReceiveProjects,
      payload: {
        errors: [
          {
            title: String(error),
          },
        ],
      },
      meta: {
        title:
          "Errors thrown while attempting to fetch and dispatch receiving of projects data",
      },
    });
  }
}

export default [takeLatest(SliceActionType.FetchProjects, fetchProjectsSaga)];
