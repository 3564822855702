import { useEffect } from "react";

import { AppPath } from "src/constants/routes";
import { getGlobalThis } from "../globals/document";

export const useDocumentHeaders = ({
  description,
  title,
}: {
  description: string;
  title: string;
}) => {
  useEffect(() => {
    const initialDocumentTitle = `${document.title}`;
    document.title = title;
    return () => {
      if (initialDocumentTitle) {
        document.title = initialDocumentTitle;
      }
    };
  }, [title]);

  useEffect(() => {
    const metaElement = document.querySelector('meta[name="description"]');
    const initialDescription = metaElement?.getAttribute("content");
    if (metaElement && initialDescription && description) {
      metaElement.setAttribute("content", description);
    }
    return () => {
      if (metaElement && initialDescription && description) {
        metaElement.setAttribute("content", initialDescription);
      }
    };
  }, [description]);

  useEffect(() => {
    const canonicalElement = document.querySelector("link[rel=canonical]");
    const initialCanonical = canonicalElement?.getAttribute("href");
    const currentPathName = getGlobalThis().location.pathname;
    if (canonicalElement && currentPathName !== AppPath.Base) {
      canonicalElement.setAttribute(
        "href",
        [
          getGlobalThis().location.protocol,
          "//",
          getGlobalThis().location.host,
          currentPathName,
        ].join(""),
      );
    }
    return () => {
      if (canonicalElement && initialCanonical) {
        canonicalElement.setAttribute("href", initialCanonical);
      }
    };
  }, []);
};
