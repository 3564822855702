import { ValidAppPath } from "src/types/routes";

export enum AppPath {
  Base = "/",
  Projects = "/projects",
  Loading = "/loading",
  NotFound = "*",
}

export const orderedAppPaths: Readonly<ValidAppPath[]> = [
  AppPath.Base,
  AppPath.Projects,
];

export const appPathIndexedDetails: Readonly<
  Record<AppPath, { title: string; description: string }>
> = {
  [AppPath.Base]: {
    title: "About",
    description: "Short About Page for my personal home page",
  },
  [AppPath.Projects]: {
    title: "Projects",
    description: "List of previous projects for my personal home page.",
  },
  [AppPath.NotFound]: {
    title: "Not Found",
    description: "Invalid path within my personal home page.",
  },
  [AppPath.Loading]: {
    title: "Loading",
    description:
      "Page to show between navigating within my personal home page.",
  },
};
