import { FC, lazy, StrictMode, Suspense } from "react";

import { CookiesProvider } from "react-cookie";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter, Route, RouteProps, Routes } from "react-router-dom";

import { RENDER_ROOT_ID } from "./constants";
import {
  AppPath,
  appPathIndexedDetails,
  orderedAppPaths,
} from "./constants/routes";
import Loading from "./pages/loading";
import store from "./services/redux/store";
import { AppPageFC, ValidAppPath } from "./types/routes";

import "./styles/fonts.scss";
import "./styles/index.scss";

const NotFoundPage = lazy(() => import("./pages/not-found"));

const appPathIndexedPageComponents: Readonly<Record<ValidAppPath, AppPageFC>> =
  {
    [AppPath.Base]: lazy<AppPageFC>(() => import("./pages/about")),
    [AppPath.Projects]: lazy<AppPageFC>(() => import("./pages/projects")),
  };

const PageWithTitleAndDescription: FC<{
  description: string;
  Page: AppPageFC;
  title: string;
}> = ({ description, Page, title }) => (
  <Page title={title} description={description} />
);

const appPathIndexedElements = orderedAppPaths.reduce((acc, val) => {
  acc[val] = (
    <PageWithTitleAndDescription
      title={appPathIndexedDetails[val].title}
      description={appPathIndexedDetails[val].description}
      Page={appPathIndexedPageComponents[val]}
    />
  );
  return acc;
}, {} as Record<ValidAppPath, RouteProps["element"]>);

const App = () => (
  <Suspense fallback={<Loading />}>
    <Routes>
      {orderedAppPaths.map((orderedAppPath) => (
        <Route
          path={orderedAppPath}
          key={orderedAppPath}
          element={appPathIndexedElements[orderedAppPath]}
        />
      ))}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </Suspense>
);

const container = document.getElementById(RENDER_ROOT_ID);
if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <CookiesProvider>
        <BrowserRouter>
          <ReduxProvider store={store}>
            <App />
          </ReduxProvider>
        </BrowserRouter>
      </CookiesProvider>
    </StrictMode>,
  );
}
