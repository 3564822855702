import MainLayout from "src/components/main-layout";
import { AppPath, appPathIndexedDetails } from "src/constants/routes";
import { useDocumentHeaders } from "src/utils/headers";

import styles from "./main.scss";

const title = appPathIndexedDetails[AppPath.Loading].title;

const Loading = () => {
  useDocumentHeaders({
    description: appPathIndexedDetails[AppPath.Loading].description,
    title,
  });

  return (
    <MainLayout>
      <div className={styles.lining}>
        <h1>Woody See: {title}</h1>
        <h2>Loading...</h2>
      </div>
    </MainLayout>
  );
};

export default Loading;
