import {
  FC,
  HTMLAttributes,
  OptionHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";

import styled from "styled-components";

import { getGlobalThis } from "src/utils/globals/document";

import colours from "src/styles/colours.scss";
import styles from "./main.scss";

const chooseColour =
  ([dayColour, nightColour]: [string, string]) =>
  (props: { $theme: "day" | "night" }) =>
    props.$theme === "day" ? dayColour : nightColour;

type CommonSCProps = {
  $theme: "day" | "night";
  $width?: CSSStyleDeclaration["width"];
};

const NativeSelect = styled.select<CommonSCProps>`
  background: ${chooseColour([
    colours.dayBackLightHSL,
    colours.dayForeBrandHSL,
  ])};
  border-color: ${chooseColour([
    colours.dayForeBrandHSL,
    colours.dayBackLightHSL,
  ])};
  color: ${chooseColour([colours.dayForeBrandHSL, colours.dayBackLightHSL])};
  width: ${(props) => props.$width || "auto"};
  &:disabled {
    background: ${chooseColour([
      colours.dayForeBrandHSL,
      colours.dayBackLightHSL,
    ])};
    border-color: ${chooseColour([
      colours.dayForeBrandHSL,
      colours.dayBackLightHSL,
    ])};
    color: ${chooseColour([colours.dayBackLightHSL, colours.dayForeBrandHSL])};
  }
  &:hover,
  &:focus {
    background: ${chooseColour([
      colours.dayForeBrandHSL,
      colours.dayBackLightHSL,
    ])};
    border-color: ${chooseColour([
      colours.dayBackLightHSL,
      colours.dayForeBrandHSL,
    ])};
    color: ${chooseColour([colours.dayBackLightHSL, colours.dayForeBrandHSL])};
  }
  option {
    background-color: ${chooseColour([
      colours.dayBackLightHSL,
      colours.dayForeBrandHSL,
    ])};
    color: ${chooseColour([colours.dayForeBrandHSL, colours.dayBackLightHSL])};
  }
`;

const PresentationalOptions = styled.div<CommonSCProps>`
  border-color: ${chooseColour([
    colours.dayForeBrandHSL,
    colours.dayBackLightHSL,
  ])};
  width: ${(props) => props.$width || "auto"};
`;
const PresentationalOption = styled.div<CommonSCProps>`
  background-color: ${chooseColour([
    colours.dayBackLightHSL,
    colours.dayForeBrandHSL,
  ])};
  color: ${chooseColour([colours.dayForeBrandHSL, "white"])};
  cursor: pointer;
  padding: 8px;
  width: ${(props) => props.$width || "auto"};
  &[aria-selected="true"] {
    color: ${chooseColour(["grey", "grey"])};
    cursor: default;
  }
  &:hover:not([aria-selected="true"]) {
    background-color: ${chooseColour([
      colours.dayForeBrandHSL,
      colours.dayBackLightHSL,
    ])};
    color: ${chooseColour([
      colours.dayBackLighterHSL,
      colours.dayForeBrandHSL,
    ])};
  }
`;

const BasicSelect = <
  OptionValue extends OptionHTMLAttributes<HTMLOptionElement>["value"],
>(props: {
  defaultValue: OptionValue;
  liningClassName?: HTMLAttributes<HTMLDivElement>["className"];
  optionClassName?: HTMLAttributes<HTMLOptionElement>["className"];
  onOptionSelect: (selectedValue: OptionValue) => void;
  options: {
    // children: OptionHTMLAttributes<HTMLOptionElement>["children"];
    label: OptionHTMLAttributes<HTMLOptionElement>["label"];
    value: OptionValue;
  }[];
  width?: CSSStyleDeclaration["width"];
}): ReturnType<FC> => {
  const {
    defaultValue,
    liningClassName,
    onOptionSelect,
    optionClassName,
    options,
    width,
  } = props;
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [selectIsFocused, setSelectIsFocused] = useState(false);
  const liningRef = useRef<HTMLDivElement | null>(null);
  const selectRef = useRef<HTMLSelectElement | null>(null);

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key) {
        setSelectIsFocused(false);
      }
    };
    const handleMouseUp: Parameters<
      (typeof globalThis)["addEventListener"]
    >[1] = (event) => {
      const currentLiningRef = liningRef.current;
      const currentSelectRef = selectRef.current;
      if (
        currentLiningRef &&
        !currentLiningRef.contains(event.target as HTMLElement)
      ) {
        setSelectIsFocused(false);
      }
      if (
        currentSelectRef &&
        !currentSelectRef.contains(event.target as HTMLElement)
      ) {
        setSelectIsFocused(false);
      }
    };
    getGlobalThis().addEventListener("keyup", handleKeyUp);
    getGlobalThis().addEventListener("mouseup", handleMouseUp);
    return () => {
      getGlobalThis().removeEventListener("keyup", handleKeyUp);
      getGlobalThis().removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  return (
    <div
      className={
        liningClassName
          ? [styles.lining, liningClassName].join(" ")
          : styles.lining
      }
      ref={liningRef}
    >
      <NativeSelect
        $theme="day"
        $width={width}
        onChange={(event) => {
          onOptionSelect(event.target.value as OptionValue);
        }}
        onMouseDown={(event) => {
          event.preventDefault();
          setSelectIsFocused(true);
        }}
        onKeyDown={(event) => {
          if (event.key === " " || event.key === "Enter") {
            setSelectIsFocused(false);
          }
        }}
        ref={selectRef}
        value={selectedValue}
      >
        {options.map(({ label, value }) => {
          return (
            <option
              className={optionClassName}
              key={label}
              label={label}
              value={value}
            >
              {label}
            </option>
          );
        })}
      </NativeSelect>
      <PresentationalOptions
        aria-hidden="true"
        className={
          selectIsFocused
            ? [styles["presentational-options"], styles["selected"]].join(" ")
            : styles["presentational-options"]
        }
        $theme="day"
      >
        {options.map(({ label, value }) => {
          return (
            <PresentationalOption
              $theme="day"
              $width={width}
              aria-label={label}
              aria-selected={value === selectedValue}
              className={
                optionClassName
                  ? [styles["presentational-option"], optionClassName].join(" ")
                  : ""
              }
              onClick={() => {
                setSelectedValue(value);
                onOptionSelect(value);
                setSelectIsFocused(false);
              }}
              key={label}
            >
              {label}
            </PresentationalOption>
          );
        })}
      </PresentationalOptions>
    </div>
  );
};

export default BasicSelect;
