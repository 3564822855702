import { CSSProperties, FC } from "react";

import styled from "styled-components";

const WWWLogoWrapper = styled.div<{
  $colour: CSSProperties["color"];
  $width: CSSProperties["width"];
}>`
  color: ${(props) => props.$colour};
  height: ${(props) => props.$width};
  width: ${(props) => props.$width};
`;

const WWWLogo: FC<{
  colour: CSSProperties["color"];
  width: CSSProperties["width"];
}> = ({ colour, width }) => (
  <WWWLogoWrapper $colour={colour} $width={width}>
    <svg
      viewBox="0 0 24 24"
      version="1.1"
      xmlSpace="preserve"
      width={width}
      height={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M 12,0 A 11.999999,12 0 0 0 1.89,5.540625 C 1.88785,5.5391562 1.850625,5.5162497 1.850625,5.5162497 v 0.095625 A 11.999999,12 0 0 0 0,12 11.999999,12 0 0 0 1.850625,18.388125 v 0.07125 c 0,0 0.059307,0.04059 0.065625,0.045 A 11.999999,12 0 0 0 12,24 11.999999,12 0 0 0 22.085625,18.49875 c 0.0054,-0.0036 0.06188,-0.03938 0.06188,-0.03938 v -0.0675 A 11.999999,12 0 0 0 24,12 11.999999,12 0 0 0 22.149375,5.60625 v -0.09 c 0,0 -0.03711,0.024661 -0.03937,0.02625 A 11.999999,12 0 0 0 12,0 Z m 0,2.4 a 9.6,9.6 0 0 1 8.045625,4.36875 C 19.101818,7.2475631 18.008236,7.68 17.1375,7.68 15.191285,7.68 13.989341,5.51625 12.043125,5.51625 10.096909,5.51625 8.8930907,7.68 6.946875,7.68 6.0711674,7.68 4.9544758,7.2414329 3.988125,6.759375 A 9.6,9.6 0 0 1 12,2.4 Z m -0.04312,6.028125 c 1.946216,0 3.150034,2.165625 5.09625,2.165625 1.253428,0 2.994975,-0.8927436 4.08375,-1.531875 A 9.6,9.6 0 0 1 21.54,10.93125 c -0.988524,0.624098 -2.985931,1.764375 -4.4025,1.764375 -1.946215,0 -3.148159,-2.16375 -5.094375,-2.16375 -1.946216,0 -3.1500343,2.16375 -5.09625,2.16375 -1.4132128,0 -3.4469996,-1.135545 -4.4625,-1.760625 A 9.6,9.6 0 0 1 2.86125,9.075 c 1.065513,0.6380467 2.7578692,1.51875 4.00125,1.51875 1.9462157,0 3.148159,-2.165625 5.094375,-2.165625 z m 0,5.015625 c 1.946216,0 3.150034,2.165625 5.09625,2.165625 1.3555,0 3.273411,-1.040788 4.325625,-1.678125 a 9.6,9.6 0 0 1 -0.984375,2.685 c -1.013511,0.545414 -2.275938,1.095 -3.256875,1.095 -1.946215,0 -3.150034,-2.16375 -5.09625,-2.16375 -1.946215,0 -3.1481593,2.16375 -5.094375,2.16375 -1.0022021,0 -2.3178373,-0.571974 -3.36375,-1.12875 A 9.6,9.6 0 0 1 2.625,13.94625 c 1.0324209,0.63862 2.8921965,1.663125 4.2375,1.663125 1.9462157,0 3.148159,-2.165625 5.094375,-2.165625 z m 0,5.015625 c 1.713001,0 2.851861,1.675651 4.426875,2.0775 A 9.6,9.6 0 0 1 12,21.6 9.6,9.6 0 0 1 7.59,20.521875 c 1.5450579,-0.427524 2.675014,-2.0625 4.366875,-2.0625 z"
      />
    </svg>
  </WWWLogoWrapper>
);

export default WWWLogo;
