import type store from "./store";
// Infer the `RootState` and `AppDispatch` types from the store itself
type RootState = ReturnType<typeof store.getState>;

export enum SliceActionType {
  FetchProjects = "projects/fetchProjects",
  ReceiveProjects = "projects/receiveProjects",
}

export type { RootState };
