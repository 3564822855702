import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import projectsReducer from "src/services/redux/projects/slice";
import rootSaga from "./sagas";
import { SliceActionType } from "./types";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  devTools: true,
  reducer: {
    projects: projectsReducer,
  },
  middleware: (getDefaultMiddleware) => {
    // See https://redux-toolkit.js.org/api/getDefaultMiddleware for
    // full usage
    return getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [SliceActionType.FetchProjects],
      },
    }).prepend(
      // correctly typed middlewares can just be used
      sagaMiddleware,
    );
  },
});

sagaMiddleware.run(rootSaga);

export default store;
