import { FC, PropsWithChildren } from "react";

import { useNavigate } from "react-router-dom";

import WWWLogo from "src/components/www-logo";
import { appPathIndexedDetails, orderedAppPaths } from "src/constants/routes";
import { ValidAppPath } from "src/types/routes";
import BasicButton from "../basic-button";
import BasicSelect from "../basic-select";

import colours from "src/styles/colours.scss";
import styles from "./main.scss";

const options: { label: string; value: ValidAppPath }[] = orderedAppPaths.map(
  (orderedAppPath) => ({
    label: appPathIndexedDetails[orderedAppPath].title,
    value: orderedAppPath,
  }),
);

const MainLayout: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const navigate = useNavigate();

  const currentPath = window.location.pathname;

  return (
    <>
      <div className={styles["navigation-bar"]}>
        <WWWLogo colour={colours.dayForeBrandHSL} width="48px" />
        <nav
          aria-label="Main navigation for mobile"
          className={styles["for-mobile"]}
        >
          <BasicSelect
            liningClassName={styles["select-lining"]}
            optionClassName={styles["option"]}
            defaultValue={currentPath}
            options={options}
            onOptionSelect={(selectedValue) => {
              navigate(selectedValue);
            }}
            width="150px"
          />
        </nav>
        <nav
          aria-label="Main navigation for desktop"
          className={styles["for-desktop"]}
        >
          {options.map(({ label, value }, i) => {
            const goToPage = () => {
              navigate(value);
            };
            return (
              <BasicButton
                aria-label={label}
                aria-selected={currentPath === value}
                className={styles["navigation-link"]}
                onClick={goToPage}
                onKeyDown={goToPage}
                key={i}
                disabled={currentPath === value}
                selected={currentPath === value}
                type="button"
              >
                {label}
              </BasicButton>
            );
          })}
        </nav>
      </div>
      <main className={styles["main"]}>{children}</main>
    </>
  );
};

export default MainLayout;
