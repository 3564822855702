import { ButtonHTMLAttributes, FC, PropsWithChildren } from "react";

import styled from "styled-components";

import colours from "src/styles/colours.scss";
import styles from "./main.scss";

const chooseColour =
  ([dayColour, nightColour]: [string, string]) =>
  (props: { $theme: "day" | "night" }) =>
    props.$theme === "day" ? dayColour : nightColour;

const ButtonStyled = styled.button<{
  $theme: "day" | "night";
}>`
  background: ${chooseColour([
    colours.dayBackLightHSL,
    colours.dayForeBrandHSL,
  ])};
  border: solid 1px
    ${chooseColour([colours.dayForeBrandHSL, colours.dayBackLightHSL])};
  color: ${chooseColour([colours.dayForeBrandHSL, colours.dayBackLightHSL])};
  &:disabled,
  &[aria-selected="true"] {
    background: ${chooseColour([
      colours.dayForeBrandHSL,
      colours.dayBackLightHSL,
    ])};
    border: solid 1px solid 1px
      ${chooseColour([colours.dayForeBrandHSL, colours.dayBackLightHSL])};
    color: ${chooseColour([colours.dayBackLightHSL, colours.dayForeBrandHSL])};
  }
  &:hover {
    background: ${chooseColour([
      colours.dayForeBrandHSL,
      colours.dayBackLightHSL,
    ])};
    border: solid 1px solid 1px
      ${chooseColour([colours.dayBackLightHSL, colours.dayForeBrandHSL])};
    color: ${chooseColour([colours.dayBackLightHSL, colours.dayForeBrandHSL])};
  }
`;

const BasicButton: FC<
  PropsWithChildren<{
    "aria-label": ButtonHTMLAttributes<HTMLButtonElement>["aria-label"];
    className?: ButtonHTMLAttributes<HTMLButtonElement>["className"];
    disabled: ButtonHTMLAttributes<HTMLButtonElement>["disabled"];
    onClick: ButtonHTMLAttributes<HTMLButtonElement>["onClick"];
    onKeyDown: ButtonHTMLAttributes<HTMLButtonElement>["onKeyDown"];
    selected: boolean;
    type: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  }>
> = (props) => {
  const { children, className, disabled, onClick, selected, type } = props;
  return (
    <ButtonStyled
      $theme="day"
      aria-label={props["aria-label"]}
      aria-selected={selected}
      className={[styles.lining, className].join(" ")}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </ButtonStyled>
  );
};

export default BasicButton;
