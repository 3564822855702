import { createAction, createSlice } from "@reduxjs/toolkit";

import { SliceActionType } from "../types";
import { ReceiveProjectsAction, State } from "./types";

// Define the initial state using that type
const initialState: State = {
  data: [],
  status: {
    message: "",
    value: "idle",
  },
};

const slice = createSlice({
  // Sets prefix for action names. See https://redux-toolkit.js.org/api/createSlice#reducers
  name: "projects",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  // NOTE:
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  extraReducers: (builder) => {
    builder
      .addCase(createAction(SliceActionType.FetchProjects), (state) => {
        state.status.message = "Fetching projects...";
        state.status.value = "pending";
      })
      .addCase(
        createAction<ReceiveProjectsAction["payload"]>(
          SliceActionType.ReceiveProjects,
        ),
        (state, action) => {
          if (action.payload.errors) {
            state.status.message = "Unable to receive fetched projects";
            state.status.value = "rejected";
            return;
          }
          if (action.payload.data) {
            state.data = action.payload.data;
            state.status.message = "Received projects successfully";
            state.status.value = "fulfilled";
          }
        },
      );
  },
});

export default slice.reducer;
